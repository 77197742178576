// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

:root {
	--ion-color-orange: #fb7424;
	--ion-color-orange-rgb: 251,116,36;
	--ion-color-orange-contrast: #000000;
	--ion-color-orange-contrast-rgb: 0,0,0;
	--ion-color-orange-shade: #dd6620;
	--ion-color-orange-tint: #fb823a;

  --ion-color-black: #000000;
	--ion-color-black-rgb: 0,0,0;
	--ion-color-black-contrast: #ffffff;
	--ion-color-black-contrast-rgb: 255,255,255;
	--ion-color-black-shade: #000000;
	--ion-color-black-tint: #1a1a1a;

  --ion-color-primary: var(--ion-color-black);
  --ion-color-primary-rgb: var(--ion-color-black-rgb);
  --ion-color-primary-contrast: var(--ion-color-black-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-black-shade);
  --ion-color-primary-tint: var(--ion-color-black-tint);
  
  --ion-color-secondary: var(--ion-color-orange);
  --ion-color-secondary-rgb: var(--ion-color-orange-rgb);
  --ion-color-secondary-contrast: var(--ion-color-orange-contrast);
  --ion-color-secondary-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-secondary-shade: var(--ion-color-orange-shade);
  --ion-color-secondary-tint: var(--ion-color-orange-tint);

  --highlight-color: var(--ion-color-orange);
}

.ion-valid.sc-ion-input-md-h {
  --highlight-color: var(--ion-color-orange);
}


.ion-color-orange {
	--ion-color-base: var(--ion-color-orange);
	--ion-color-base-rgb: var(--ion-color-orange-rgb);
	--ion-color-contrast: var(--ion-color-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-orange-shade);
	--ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black-rgb);
	--ion-color-contrast: var(--ion-color-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-black-shade);
	--ion-color-tint: var(--ion-color-black-tint);
}

